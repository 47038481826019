import React, { useState } from "react";
import {
  HomeIcon,
  UserIcon,
  WrenchScrewdriverIcon,
  BriefcaseIcon,
  ChatBubbleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/outline";

const styleIcons =
  "h-6 w-6 text-primary-white hover:text-light-blue cursor-pointer   ";
const styleText =
  "text-primary-white text-sm text-center tracking-wide py-1 hover:text-light-blue ";

const Sidebar = () => {
  const Icons = [
    { id: 1, title: "Home", IconName: HomeIcon },
    { id: 2, title: "About", IconName: UserIcon },
    { id: 3, title: "Skills", IconName: WrenchScrewdriverIcon },
    { id: 4, title: "Projects", IconName: BriefcaseIcon },
    { id: 5, title: "Contact", IconName: ChatBubbleLeftIcon },
  ];

  const [isShowing, setIsShowing] = useState(false);
  return (
    // There was relative
    <nav>
      <div className={` hidden md:block  max-w-fit  `}>
        <div
          className={` " absolute top-10 -right-6 h-16 w-16 bg-secondary-black rounded-full flex items-center justify-center " `}
        >
          <ChevronDoubleRightIcon
            className={`${styleIcons}  ml-6  z-20 ease-out duration-300 ${
              isShowing ? "rotate-180 duration-300 ease-in" : null
            }  `}
            title="Arrow"
            onClick={() => {
              setIsShowing((isShowing) => !isShowing);
            }}
          />
        </div>

        <div
          className={`" relative  text-white  w-fit px-6 h-screen bg-secondary-black backdrop-blur-lg font-sans  ease-linear duration-300 "`}
        >
          {isShowing ? (
            <div className="  flex flex-col justify-center ml-3 h-full gap-8  ">
              {Icons.map((item) => {
                return (
                  <a
                    className="scroll-smooth delay-300"
                    href={`#${item.title}`}
                    key={item.id}
                  >
                    <div className="flex gap-3   hover:cursor-pointer">
                      <item.IconName className={`${styleIcons}   `} />
                      <h2 className={styleText}> {item.title} </h2>
                    </div>
                  </a>
                );
              })}
            </div>
          ) : (
            <div className="  flex flex-col justify-center items-center h-full gap-8  ">
              {Icons.map((item) => {
                return (
                  <a href={`#${item.title}`} key={item.id}>
                    <item.IconName className={`${styleIcons}  `} />
                  </a>
                );
              })}
            </div>
          )}
        </div>
      </div>

      <div className=" flex md:hidden  justify-center items-center mx-auto  ">
        <div className="flex  gap-5 py-3 justify-center w-3/4  items-center bg-primary-black  px-5 rounded-3xl ">
          {Icons.map((item) => {
            return (
              <a href={`#${item.title}`} key={item.id}>
                <item.IconName
                  className={`${styleIcons}   hover:h-8 hover:w-8    `}
                />
              </a>
            );
          })}
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
