import Home from "./components/Home";
import Sidebar from "./components/Sidebar";
import About from "./components/About";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import "./styles/Landing.css";


function App() {
  return (
    <main className="bg-gradient-to-l bg-primary-black to-black flex   md:justify-center ">
      <div className="  fixed bottom-5 w-full   md:w-fit   md:sticky  md:h-screen md:top-0">
        <Sidebar />
      </div>

      <div className=" background   h-fit w-full bg-slate-300 lg:w-2/3  ">
        <Home />
        <About />
        <Skills />
        <Projects />
        <Contact />
      </div>
    </main>
  );
}

export default App;
