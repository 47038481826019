import React from "react";

import { Icon } from "@iconify/react";

const Contact = () => {
  const SocialMedia = [
    {
      id: 1,
      name: "LinkedIn",
      iconName: "prime:linkedin",
      link: "https://www.linkedin.com/in/binod-khatri-608a20239/",
    },
    {
      id: 2,
      name: "Instagram",
      iconName: "ph:instagram-logo",
      link: "https://l.facebook.com/l.php?u=https%3A%2F%2Finstagram.com%2F__.sleepy._.head_%3Figshid%3DZDdkNTZiNTM%253Dl%26fbclid%3DIwAR31iGOo9ZsuRU_JuKwQVvZ7Kdi4A1JyuT_1YcXOLpJTI7iuc4o81WzP-ns&h=AT3Ll5-yTv5Kvjj7NVz2abwxiGE2yjS7T1hGomDvi-MTv7wBu2kLWx4HlmBgMYC482o123jPsUIJDVnQfFwBCsq9MvYZgmYkylTiTXx1WsLDHEZyX91EF2GsG6K9zr-jVB9RpA",
    },

    {
      id: 3,
      name: "Github",
      iconName: "ri:github-fill",
      link: "https://github.com/Tengoku18",
    },
    { id: 4, name: "Telegram", iconName: "la:telegram-plane", link: "" },
    { id: 5, name: "Twitter", iconName: "mdi:twitter", link: "" },
    { id: 6, name: "WhatsApp", iconName: "ph:whatsapp-logo", link: "" },
  ];

  return (
    <section
      className="font-poppins  min-h-fit my-10 md:my-0 md:h-screen  flex flex-col justify-around items-center "
      id="Contact"
    >
      <div className=" tracking-wider  mb-16 md:mb-0 w-full text-center   text-lg font-thin text-light-blue">
        <h2> Get In Touch</h2>
        <h3 className="text-sm">With me</h3>
      </div>
      <div className="flex flex-wrap justify-center items-center">
        <div className=" flex  md:w-1/2 flex-col ">
          <div className="flex flex-wrap gap-5   justify-center">
            {SocialMedia.map((item) => {
              return (
                <div
                  key={item.id}
                  className=" basis-1/3 flex flex-col cursor-pointer  text-primary-white hover:text-primary-black bg-transparent hover:bg-light-blue justify-center items-center py-8 px-10 border border-primary-white rounded-lg  "
                >
                  <a
                    href={item.link}
                    className="flex flex-col items-center justify-center"
                  >
                    <Icon icon={item.iconName} className="h-6 w-6 mb-1/2" />
                    <h2 className="text-sm font-light tracking-wider ">
                      {item.name}
                    </h2>
                  </a>
                </div>
              );
            })}
          </div>
        </div>

        <div className="  flex mt-12 w-3/4 mb-24 md:mb-0 md:mt-0 md:w-1/2  justify-center  ">
          <form className="flex flex-col gap-4 w-full">
            <div className="flex flex-col gap-2 text-primary-white">
              <label className="font-thin text-md" htmlFor=" name">
                Full Name
              </label>
              <input
                type="text"
                className=" pl-2 h-10 w-4/5 border-primary-white border bg-transparent rounded-sm"
                name="name"
                required
              />
            </div>
            <div className="flex flex-col gap-2 text-primary-white">
              <label className="font-thin text-md" htmlFor=" email">
                E-mail
              </label>
              <input
                type="email"
                className=" pl-2 h-10 w-4/5 border-primary-white border bg-transparent rounded-sm"
                name="email"
                required
              />
            </div>
            <div className="flex flex-col gap-2 text-primary-white">
              <label className="font-thin text-md" htmlFor=" message">
                Message
              </label>
              <textarea
                className="pt-1 pl-2 h-36 w-4/5 border-primary-white border bg-transparent rounded-sm"
                name="message"
                required
              />
            </div>

            <button
              type="submit"
              className="px-2 py-2 w-24 rounded-md font-thin text-md text-primary-white  hover:text-light-blue bg-primary-black border border-primary-white hover:bg-transparent"
            >
              Send
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
