import React from "react";
import { Icon } from "@iconify/react";
const Home = () => {
  const Icons = [
    {
      id: 1,
      title: "Instagram",
      IconName: "ph:instagram-logo",
      urlLink:
        "https://l.facebook.com/l.php?u=https%3A%2F%2Finstagram.com%2F__.sleepy._.head_%3Figshid%3DZDdkNTZiNTM%253Dl%26fbclid%3DIwAR31iGOo9ZsuRU_JuKwQVvZ7Kdi4A1JyuT_1YcXOLpJTI7iuc4o81WzP-ns&h=AT3Ll5-yTv5Kvjj7NVz2abwxiGE2yjS7T1hGomDvi-MTv7wBu2kLWx4HlmBgMYC482o123jPsUIJDVnQfFwBCsq9MvYZgmYkylTiTXx1WsLDHEZyX91EF2GsG6K9zr-jVB9RpA",
    },
    {
      id: 2,
      title: "Facebook",
      IconName: "mdi:facebook",
      urlLink: "https://www.facebook.com/bnod.khatri.9/",
    },
    {
      id: 3,
      title: "Github",
      IconName: "ri:github-fill",
      urlLink: "https://github.com/Tengoku18",
    },
    {
      id: 4,
      title: "LinkedIn",
      IconName: "prime:linkedin",
      urlLink: "https://www.linkedin.com/in/binod-khatri-608a20239/",
    },
  ];
  const styleIcons =
    "h-6 w-6 text-primary-white hover:text-light-blue cursor-pointer   ";
  return (
    <section
      className="font-poppins h-screen md:h-screen static   flex justify-around flex-col px-5 md:px-10  "
      id="Home"
    >
      <h1 className=" hidden  md:absolute md:block   bottom-14  -right-5  text-xs  text-primary-white -rotate-90  lg:right-28 xl:right-48 ">
        {" "}
        <span className="flex">
          <Icon icon="ph:copyright" className="h-3 w-3 mt-1" />
          Tengoku|2023
        </span>
      </h1>
      <div className="  flex  w-full justify-end py-2 mt-3">
        <div className=" hidden md:flex text-xl tracking-wider  font-medium  md:text-2xl ">
          <h1 className="text-primary-white">Binod </h1>{" "}
          <h1 className="text-light-blue ml-1"> Khatri</h1>
        </div>
      </div>
      <div className="   flex font-sans my-16 md:my-14 justify-around items-center  ">
        <div className="  px-8 md:px-0 flex flex-col ">
          <h1 className=" text-6xl md:text-7xl font-medium text-primary-white">
            HI, I am
          </h1>
          <h1 className=" text-6xl md:text-7xl font-medium text-primary-white  my-1">
            Binod
          </h1>
          <h2 className=" text-xl md:text-2xl font-light text-light-blue  mt-3 mb-6 pl-1">
            {" "}
            Frontend Developer{" "}
          </h2>
          <button className="py-1 md:py-2 px-2 w-28 md:w-32 font-light rounded-full bg-primary-black border-2 border-primary-white text-primary-white hover:bg-transparent">
            {" "}
            GET CV{" "}
          </button>
        </div>
        <div className=" hidden md:block  relative ">
          <img
            src="/men.png"
            className="h-80 w-80 md:w-96 md:h-96"
            alt="A handsome Man"
          />
        </div>
      </div>
      <div className=" hidden md:flex justify-center items-center mt-3">
        {Icons.map((icon, index) => {
          return (
            <a href={icon.urlLink} key={index}>
              <div className="mx-2">
                <Icon icon={icon.IconName} className={styleIcons} />
              </div>
            </a>
          );
        })}
      </div>
    </section>
  );
};

export default Home;
