import React from "react";

const Projects = () => {
  const Projects = [
    {
      id: 1,
      name: "Clover Exams",
      ImageUrl: "cloverlogo.svg",
      link: "https://www.cloverexams.com/",
    },
    {
      id: 2,
      name: "Footstep Education Consultancy",
      ImageUrl: "footstep.jpg",
      link: "https://footstepeducation.com/",
    },
    {
      id: 3,
      name: "Sunaulo Shopping",
      ImageUrl: "sunaulo.png",
      link: "https://sunaulo-web.vercel.app/",
    },
    {
      id: 4,
      name: "Frogger Game",
      ImageUrl: "Frogger.jpg",
      link: "https://frogger-game-two.vercel.app/",
    },
    {
      id: 5,
      name: "Apple-Clone",
      ImageUrl: "Hs.jpg",
      link: "https://appleclone.vercel.app/",
    },
    {
      id: 6,
      name: "Online Shopping",
      ImageUrl: "cart.jpg",
      link: "https://e-commerce-eight-gules.vercel.app/",
    },

    {
      id: 7,
      name: "Redux Toolkit",
      ImageUrl: "reduxx.jpg",
      link: "https://crud-redux-toolkit.vercel.app/",
    },
  ];
  return (
    <section
      className=" h-fit my-10 md:my-0 lg:my-10 xl:my-0 md:min-h-screen "
      id="Projects"
    >
      <div className="flex  h-full flex-col  py-10 rounded-lg justify-center items-center">
        <div className="mb-16">
          <h1 className="text-xl font-poppins font-thin text-light-blue">
            {" "}
            My Recent Projects{" "}
          </h1>
        </div>
        <div className=" flex flex-wrap gap-10 justify-center  md:w-3/5">
          {Projects.map((porject) => {
            return (
              <a href={porject.link} key={porject.id}>
                <div className="flex  justify-center items-center group cursor-pointer hover:-translate-y-2  hover:bg-primary-black flex-col border-secondary-black border rounded-lg  py-4 px-10 ">
                  <div className=" mb-1">
                    <img
                      src={porject.ImageUrl}
                      className="h-20 w-20 rounded-full
                  "
                      alt="No"
                    />
                  </div>

                  <h3 className="text-sm font-light text-light-blue w-32 text-center  ">
                    {" "}
                    {porject.name}
                  </h3>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Projects;
