import React from "react";
import { CheckBadgeIcon } from "@heroicons/react/24/solid";

const Skills = () => {
  const Languages = [
    { name: "HTML", level: "Expert" },
    { name: "CSS", level: "Proficient" },
    { name: "JavaScript", level: "Intermediate" },
    { name: "Sass", level: "Intermediate" },
    { name: "TypeScript", level: "Intermediate" },
    { name: "C", level: "Novice" },
    { name: "XML", level: "Novice" },
  ];
  const Frameworks = [
    { name: "React JS", level: "Proficient" },
    { name: "Tailwind CSS", level: "Proficient" },
    { name: "Bootstrap", level: "Intermediate" },
    { name: "Next JS", level: "Intermediate" },
    { name: "Rest API", level: "Intermediate" },
    { name: "React Router", level: "Intermediate" },
    { name: "Node JS", level: "Novice" },
  ];
  const Tools = [
    { name: "Redux Toolkit", level: "Intermediate" },
    { name: "VS Code", level: "Proficient" },
    { name: "Github", level: "Intermediate" },
    { name: "Figma", level: "Intermediate" },
    { name: "Web Pack", level: "Novice" },
  ];

  return (
    <section
      className=" my-10 md:my-0 h-fit md:h-screen  text-primary-white flex justify-center items-center "
      id="Skills"
    >
      <div className="flex  flex-col  h-fit w-11/12 md:w-4/5   ">
        <div className="font-poppins mt-10 md:mt-0 mb-16 text-xl font-light text-center text-light-blue tracking-wide">
          {" "}
          <h1> Skill I Have </h1>{" "}
        </div>
        <div className="flex gap-5 flex-wrap md:flex-nowrap justify-around">
          <div className=" flex flex-col text-center bg-secondary-black hover:bg-transparent  hover:transition hover:ease-out hover:delay-100 border-secondary-black border rounded-lg py-6">
            <h1 className="text-normal font-extralight text-primary-white mb-10">
              Languages
            </h1>
            <div className="grid  grid-cols-2  gap-5 cursor-pointer ">
              {Languages.map((item, index) => {
                return (
                  <div key={index} className="flex mx-5  text-left">
                    <CheckBadgeIcon className="h-5 w-5 text-light-blue mx-2 mt-1" />
                    <div className="flex flex-col tracking-wide text-primary-white text-sm font-thin">
                      <h1 className="font-light">{item.name}</h1>
                      <p className="text-xs">{item.level}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className=" flex flex-col text-center bg-secondary-black hover:bg-transparent  hover:transition hover:ease-out hover:delay-100 border-secondary-black border rounded-lg py-6">
            <h1 className="text-normal font-extralight text-primary-white mb-10">
              Frameworks
            </h1>
            <div className="grid grid-cols-2  gap-5 cursor-pointer ">
              {Frameworks.map((item, index) => {
                return (
                  <div key={index} className="flex mx-5 text-left ">
                    <CheckBadgeIcon className="h-5 w-5 text-light-blue mx-2 mt-1" />
                    <div className="flex flex-col tracking-wide text-primary-white text-sm font-thin">
                      <h1 className="font-light">{item.name}</h1>
                      <p className="text-xs">{item.level}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className=" flex flex-col bg-secondary-black hover:bg-transparent hover:transition hover:ease-out hover:delay-100 text-center mt-8 mx-5 border-secondary-black border rounded-lg py-6">
          <h1 className="text-normal font-extralight text-primary-white mb-10">
            Tools
          </h1>
          <div className="grid grid-cols-2 md:grid-cols-3  gap-5 cursor-pointer ">
            {Tools.map((item, index) => {
              return (
                <div key={index} className="flex mx-3 md:mx-10 text-left ">
                  <CheckBadgeIcon className="h-5 w-5 text-light-blue mx-2 mt-1" />
                  <div className="flex flex-col tracking-wide text-primary-white text-sm font-thin">
                    <h1 className="font-light">{item.name}</h1>
                    <p className="text-xs">{item.level}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;
