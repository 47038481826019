import React from "react";
import {
  CheckBadgeIcon,
  LinkIcon,
  ClipboardDocumentIcon,
} from "@heroicons/react/24/outline";
const About = () => {
  const workData = [
    {
      title: "Internships/Jobs Experience ",
      icon: CheckBadgeIcon,
      years: "1+ Years",
    },
    {
      title: "Freelance/Remote Experience ",
      icon: LinkIcon,
      years: "1+ Years",
    },
    {
      title: "Projects Completed ",
      icon: ClipboardDocumentIcon,
      years: "10+ Projects",
    },
  ];

  return (
    <section
      className="font-poppins  h-fit md:h-screen lg:my-10  xl:my-0  flex flex-col items-center justify-around "
      id="About"
    >
      <div className="text-xl font-light text-center text-light-blue my-10 ">
        {" "}
        <h2>About Me</h2>{" "}
      </div>
      <div className="flex flex-col   items-center justify-center">
        <div className="flex w-11/12  items-center lg:w-4/5 mb-5">
          <p className="text-primary-white whitespace-pre-line text-justify  font-extralight text-sm tracking-wide subpixel-antialiased  ">
            <span className="text-md font-semibold tracking-wide ">
              {" "}
              Hi there!
            </span>
            <br />
            I'm a Frontend Developer with a passion for building beautiful and
            functional user interfaces. I have a solid understanding of ReactJS,
            Tailwind CSS, and Redux Toolkit. My goal is to create engaging and
            responsive websites that provide a seamless user experience. I am
            experienced in creating dynamic, high-performance web applications
            with ReactJS. I am confident in using components, hooks, and other
            key features of ReactJS to build scalable and maintainable web
            applications. In my free time, I enjoy exploring new technologies
            and staying up to date with the latest trends in frontend
            development. I am a problem solver by nature and enjoy tackling
            challenges head-on. I am always looking to improve my skills and
            grow as a developer. Thank you for taking the time to read my
            profile, and I look forward to working with you on your next
            project!
          </p>
        </div>
        <div className=" flex flex-row gap-5 w-11/12 md:w-4/5  my-5  flex-wrap">
          {workData.map((item, index) => {
            return (
              <div
                className=" grow  cursor-pointer h-60  bg-secondary-black text-center rounded-lg border-primary-black border hover:bg-transparent hover:transition hover:ease-in-out hover:duration-300 "
                key={index}
              >
                <div className="flex flex-col justify-center items-center  flex-wrap h-full">
                  <item.icon className="h-5 w-5 text-light-blue " />
                  <h1 className="text-primary-white text-xs font-thin my-1">
                    {item.title}
                  </h1>
                  <p className="text-xs text-primary-white font-thin">
                    {item.years}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default About;
